import logo from "./images/vacu_logo.png";
import "./App.css";
import uniswapLogo from "./images/uniswap-logo.png";
import baseLogo from "./images/base-logo.png";
import dexLogo from "./images/dexscreener-logo.png";
import Lottie from "react-lottie";
import * as animationData from "./images/blackhole_animation.json";
import { useContractRead, useContract } from "@thirdweb-dev/react";
import { parseEther } from "ethers/lib/utils";
import { useState } from "react";
import { contractAbi } from "./abi";
import { useBalanceForAddress } from "@thirdweb-dev/react";

function App() {
  const { contract } = useContract(
    "0xE937dd36B09C266b3d843774F62C68AEE0D12BF5",
    contractAbi
  );
  const { data: contractBalance, isLoading } = useBalanceForAddress(
    "0xE937dd36B09C266b3d843774F62C68AEE0D12BF5"
  );

  console.log(contractBalance?.displayValue);
  const { data } = useContractRead(contract, "getCirculatingSupply");
  const [contractEthBalance, setContractEthBalance] = useState(undefined);
  const [circulatingSupply, setCirculatingSupply] = useState(0);
  const [transactionSize, setTransactionSize] = useState(0);
  const [calculatedTaxes, setCalculatedTaxes] = useState(undefined);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (data && circulatingSupply === 0) {
    const floatValue = parseFloat(data, 16);
    console.log(floatValue);
    setCirculatingSupply(floatValue / 1000000000);
  }
  if (contractBalance && contractEthBalance === undefined) {
    setContractEthBalance(contractBalance);
  }

  function getTax() {
    const LOWER_TAX_RATE = 300;
    const UPPER_TAX_RATE = 3000;
    if (circulatingSupply === 0) return 0; // Prevent division by zero
    const lowerTaxRateThreshold = (circulatingSupply * 5) / 100000; // 0.005%
    const upperTaxRateThreshold = (circulatingSupply * 500) / 100000; // 0.5%
    if (transactionSize <= lowerTaxRateThreshold) {
      setCalculatedTaxes(LOWER_TAX_RATE / 100);
    } else if (transactionSize >= upperTaxRateThreshold) {
      return setCalculatedTaxes(UPPER_TAX_RATE / 100);
    } else {
      // Linear interpolation between points:
      // Correcting calculation for slope using accurate range difference calculations:
      const taxRate =
        ((transactionSize - lowerTaxRateThreshold) *
          (UPPER_TAX_RATE - LOWER_TAX_RATE)) /
          (upperTaxRateThreshold - lowerTaxRateThreshold) +
        LOWER_TAX_RATE;
      return setCalculatedTaxes(taxRate / 100);
    }
  }

  return (
    <div className="App bleading-normal tracking-normal text-white bg-black min-h-screen">
      <div className="h-full">
        <div className="w-full container p-2 mx-auto">
          <div className="w-full flex items-center justify-between">
            <img src={logo} width={40} />

            <div className="flex w-1/2 justify-end content-center">
              <a
                className="inline-block text-blue-300 no-underline hover:text-pink-500 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out"
                href="https://twitter.com/Vacu_Fi"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="orange"
                  className="bi bi-twitter-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </a>
              <a
                className="inline-block text-blue-300 no-underline hover:text-pink-500 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out"
                href="https://t.me/Vacu_Fi"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="orange"
                  className="bi bi-telegram"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                </svg>
              </a>
              <a
                className="inline-block text-[#ffa500] text-3xl no-underline text-center leading-tight ml-1 h-10 p-0 md:p-2 h-auto transform hover:scale-125 duration-300 ease-in-out"
                href="https://warpcast.com/vacufi"
                target="_blank"
              >
                W
              </a>
            </div>
          </div>
        </div>

        <div className="container pt-24 px-4 w-full mx-auto items-center">
          <div className="w-full mx-auto lg:w-2/3">
            <h1 className="my-4 text-3xl md:text-6xl text-white font-bold leading-tight text-center">
              Welcome to{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 via-orange-500 to-orange-800">
                Vacu.fi
              </span>
            </h1>
            <p className="leading-normal text-gray-400 text-base md:text-2xl mb-4 text-center">
              Linear taxes from 3% to 30%. All tax proceeds go towards the Black
              Hole, Negative Entropy keeps the token alive, forever.
            </p>
            <a
              href="https://vacufi.gitbook.io/vacufi"
              target="_blank"
              className="leading-normal text-gray-200 text-base text-sm mb-8 text-center underline"
            >
              Read the Whitepaper
            </a>
          </div>

          <div className="w-full sm:w-2/3 mx-auto">
            <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
          </div>

          {contractEthBalance && (
            <p className="mb-12 text-lg">
              Next Negative Entropy® buyback and burn value:{" "}
              <span className="bg-clip-text text-lg text-transparent bg-gradient-to-r from-yellow-400 via-orange-500 to-orange-800">
                {parseFloat(contractEthBalance.displayValue * 0.42).toFixed(4)}{" "}
                ETH
              </span>
            </p>
          )}
          <div className="w-full mx-auto pt-16 sm:pt-8">
            <div className="mx-auto space-y-4">
              <p className="text-xl font-bold">
                Tax{" "}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 via-orange-500 to-orange-800">
                  Calculator
                </span>
              </p>
              <div>
                <div className="mt-2 w-2/3 md:w-1/3 mx-auto">
                  <label
                    htmlFor="circSupply"
                    className="block py-1 text-sm font-medium text-start leading-6 text-gray-300"
                  >
                    Current supply: {circulatingSupply.toFixed(0) ?? "..."}
                  </label>
                  <input
                    id="txAmount"
                    name="txAmount"
                    type="number"
                    placeholder="Tokens amount"
                    onChange={(e) => {
                      setTransactionSize(e.target.value);
                      setCalculatedTaxes(undefined);
                    }}
                    required
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-orange-400 focus:ring-1 focus:outline-none placeholder:text-gray-400 focus:ring-none sm:text-sm sm:leading-6"
                  />
                </div>
                <button
                  type="submit"
                  onClick={() => getTax()}
                  className="w-fit mt-4 justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Calculate taxes
                </button>
                {calculatedTaxes && <p>Taxes: {calculatedTaxes.toFixed(2)}%</p>}
              </div>
            </div>
          </div>
          <div className="w-fit mx-auto pt-32">
            <div className="block sm:flex w-full justify-center pb-24 lg:pb-0">
              <a
                href="https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xE937dd36B09C266b3d843774F62C68AEE0D12BF5"
                target="_blank"
                className="mx-auto pr-0 sm:pr-24 space-y-4"
              >
                <p className="text-xl font-bold">Trade on Uniswap</p>
                <img
                  src={uniswapLogo}
                  className="h-12 mx-auto transform hover:scale-125 duration-300 ease-in-out"
                />
              </a>
              <a
                href="https://basescan.org/address/0xE937dd36B09C266b3d843774F62C68AEE0D12BF5#code"
                target="_blank"
                className="mx-auto mt-12 sm:mt-0 space-y-4 pr-0 sm:pr-24"
              >
                <p className="text-xl font-bold mt-12 sm:mt-0">Base Explorer</p>
                <img
                  src={baseLogo}
                  className="h-12 mx-auto transform hover:scale-125 duration-300 ease-in-out"
                />
              </a>
              <a
                href="https://dexscreener.com/base/0xE937dd36B09C266b3d843774F62C68AEE0D12BF5"
                target="_blank"
                className="mx-auto mt-12 sm:mt-0 space-y-4"
              >
                <p className="text-xl font-bold mt-12 sm:mt-0">DEX Screener</p>
                <img
                  src={dexLogo}
                  className="h-12 mx-auto transform hover:scale-125 duration-300 ease-in-out"
                />
              </a>
            </div>
          </div>

          <div className="w-full pt-16 pb-6 text-sm text-center fade-in mx-auto">
            <p className="text-gray-500 no-underline hover:no-underline">
              This token is an experiment on cosmic levels with no intrinsic
              value or expectation of financial return.
              <br />
              &copy; Vacu.fi 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
